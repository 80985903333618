import React from 'react';
import { connect } from 'react-redux';
import { PopEditor, Input, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
const { modifyDepartments } = iceStarkData.store.get('commonAction')?.departments;

const required = (val) => {
    if (!val) {
        return '请填写部门名称';
    } else if (val.trim() && val.trim().length > 30) {
        return '不能超过30字符';
    }
    return null;
}

@connect(
    state => ({
    }),
    {
        modifyDepartments: modifyDepartments.REQUEST
    }
)
class ModifyDepartmentName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(preProps) {
        if (this.props.visible && this.props.visible !== preProps.visible) {
            this.props.initialize({});
        }
    }

    // 提交
    handleSubmit = (data) => {
        const { record } = this.props;
        data.name = data.name.trim();
        this.props.modifyDepartments({ ...record, ...data, method: 'PUT' });
    }

    render() {
        const { onCancel, handleSubmit } = this.props;
        return (
            <div className="ModifyDepartmentName">
                <PopEditor
                    onOk={handleSubmit(this.handleSubmit)}
                    onCancel={onCancel}
                >
                    <FormGroupList>
                        <FormGroupField
                            name="name"
                            component={Input}
                            validate={[required]}
                        />
                    </FormGroupList>
                    <p className="line"></p>
                </PopEditor>
            </div>
        )
    }
}

export default reduxForm({
    form: 'ModifyDepartmentNameForm',
    initialValues: {

    }
})(ModifyDepartmentName)

