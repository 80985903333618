import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Input, FormGroupList } from 'tyb';
import { NewSelect } from 'ucode-components';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
import enums from '@/assets/enum.json';
const { modifyDepartments } = iceStarkData.store.get('commonAction')?.departments;



const requiredParentId = (val) => {
    if (!val) {
        return '请选择上级部门';
    }
    return null;
}
const requiredDepartmentType = (val) => {
    if (!val) {
        return '请选择部门类型';
    }
    return null;
}

const required = (val) => {
    if (!val) {
        return '请填写部门名称';
    } else if (val.trim() && val.trim().length > 30) {
        return '不能超过30字符';
    }
    return null;
}

const requiredCode= (val) => {
    if (!val) {
        // return '请填写部门名称';
    } else if (val.trim() && val.trim().length > 30) {
        return '不能超过30字符';
    }
    return null;
}

@connect(
    state => ({
        departmentSelectionsList: state.departments.departmentSelectionsList,
    }),
    {
        modifyDepartments: modifyDepartments.REQUEST
    }
)
class ModifyDepartment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            departmentSelector: [],
            deptTypeSelector:Object.keys(enums.deptType).map(item => { return { value: item, text: enums.deptType[item].name } }),
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        if (this.props.visible && this.props.visible !== preProps.visible) {
            this.props.initialize({});
        }

        if (this.props.departmentSelectionsList !== preProps.departmentSelectionsList) {
            const departmentSelector = this.props.departmentSelectionsList.filter(val => val.level < 5);
            this.setState({ departmentSelector });
        }


    }

    // 提交
    handleSubmit = (data) => {
        data.name = data.name.trim();
        this.props.modifyDepartments({ ...data, method: 'POST' });
    }

    render() {
        const { visible, handleVisible, handleSubmit } = this.props;
        return (
            <Dialog
                className=""
                title="新增部门"
                visible={visible}
                onOk={handleSubmit(this.handleSubmit)}
                onCancel={() => { handleVisible('', {}) }}
            >
                <div>
                    <FormGroupList>
                        <FormGroupField
                            name="parentId"
                            label="上级部门"
                            component={NewSelect}
                            style={{width:330}}
                            list={this.state.departmentSelector}
                            validate={[requiredParentId]}
                            placeholder="选择上级部门"
                        />
                          <FormGroupField
                            name="deptType"
                            label="部门类型"
                            component={NewSelect}
                            style={{width:330}}
                            list={this.state.deptTypeSelector}
                            validate={[requiredDepartmentType]}
                            placeholder="选择部门类型"
                        />
                        <FormGroupField
                            name="name"
                            label="新增部门名称"
                            component={Input}
                            validate={[required]}
                        />
                        <FormGroupField
                            name="code"
                            label="部门code"
                            component={Input}
                            validate={[requiredCode]}
                        />
                    </FormGroupList>
                </div>
            </Dialog>
        )
    }
}

export default reduxForm({
    form: 'ModifyDepartmentForm',
    initialValues: {

    }
})(ModifyDepartment)

