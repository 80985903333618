import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Input, FormGroupList, StaticFormGroup } from 'tyb';
import { NewSelect } from 'ucode-components';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
import enums from '@/assets/enum.json';
const { modifyDepartments } = iceStarkData.store.get('commonAction')?.departments;

const required = (val) => {
    if (!val) {
        return '请填写部门名称';
    } else if (val.trim() && val.trim().length > 30) {
        return '不能超过30字符';
    }
    return null;
}
const requiredCode= (val) => {
    if (!val) {
        // return '请填写部门名称';
    } else if (val.trim() && val.trim().length > 30) {
        return '不能超过30字符';
    }
    return null;
}
const requiredParentId = (val) => {
    if (!val) {
        return '请选择上级部门';
    }
    return null;
}
@connect(
    state => ({
    }),
    {
        modifyDepartments: modifyDepartments.REQUEST
    }
)
class ModifySubDepartment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deptTypeSelector:Object.keys(enums.deptType).map(item => { return { value: item, text: enums.deptType[item].name } }),
        };
    }

    componentDidMount() {
    
    
    }

    componentDidUpdate(preProps) {
        console.log(this.props.record)
        if (this.props.visible && this.props.visible !== preProps.visible) {
            this.props.initialize({deptType:this.props.record.deptType});
        }
    }

    // 提交
    handleSubmit = (data) => {
        const { record: { id } } = this.props;
        data.name = data.name.trim();
        this.props.modifyDepartments({ ...data, parentId: id, method: 'POST' });
    }

    render() {
        const { visible, handleVisible, record: { name }, handleSubmit } = this.props;
        return (
            <Dialog
                className=""
                title="新增部门"
                visible={visible}
                onOk={handleSubmit(this.handleSubmit)}
                onCancel={() => { handleVisible('sub', {}) }}
            >
                <div>
                    <FormGroupList>
                        <StaticFormGroup
                            label="上级部门"
                        >
                            {name}
                        </StaticFormGroup>
                        <FormGroupField
                            name="name"
                            label="新增子部门名称"
                            component={Input}
                            validate={[required]}
                        />
                        <FormGroupField
                            name="code"
                            label="子部门code"
                            component={Input}
                            validate={[requiredCode]}
                        />
                             <FormGroupField
                            name="deptType"
                            label="部门类型"
                            component={NewSelect}
                            style={{width:330}}
                            list={this.state.deptTypeSelector}
                            validate={[requiredParentId]}
                            placeholder="选择部门类型"
                        />
                    </FormGroupList>
                </div>
            </Dialog>
        )
    }
}

export default reduxForm({
    form: 'ModifySubDepartmentForm',
    initialValues: {

    }
})(ModifySubDepartment)

