import React from 'react';
import { connect } from 'react-redux';
import { Button, SearchInput, DataTable, Pager, notification } from 'tyb';
import { Tree } from 'antd';
const { TreeNode } = Tree;
// import IconMinusSquare from '@/icons/IconMinusSquare';
// import IconPlusSquare from '@/icons/IconPlusSquare';
import IconModify from '@/icons/IconModify';
// import { ConfirmPopout } from 'ucode-components';
import ModifyDepartment from './Components/ModifyDepartment';
import ModifySubDepartment from './Components/ModifySubDepartment';
import ModifyDepartmentName from './Components/ModifyDepartmentName';
const { departments, departmentsTree, departmentSelections, modifyDepartments, enableDepartment } = iceStarkData.store.get('commonAction')?.departments;

import './index.scss';

@connect(
  state => ({

    loading: state.departments.loading,
    departmentsList: state.departments.departmentsList,
    departmentsTreeList: state.departments.departmentsTreeList,
    modifyDepartmentsLoaded: state.departments.modifyDepartmentsLoaded,
    permissionIds: state.users.permissionIds||[],
  }),
  {
    departments: departments.REQUEST,
    departmentsTree: departmentsTree.REQUEST,
    departmentSelections: departmentSelections.REQUEST,
    modifyDepartments: modifyDepartments.REQUEST,
    enableDepartment: enableDepartment.REQUEST
  })
class DepartmentManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: [],
      size: 20
    };
    this.params = { page: 0, size: 20, sort: 'id,desc' };
    this.deleted = { YES: '上架', NO: '下架' };
    this.status = { ENABLE: '禁用', DISABLE: '启用' };
    this.columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: '部门',
        render: (val, record) => (
          <div className="depart-name">
            {val}{this.props.permissionIds.includes('data.organization.department.edit') && <IconModify onClick={() => { this.setState({ namevisible: `${record.id}_visible` }) }} />}
            {this.state.namevisible == `${record.id}_visible` && <ModifyDepartmentName
              record={record}
              onCancel={() => { this.setState({ namevisible: '' }) }}
            />}
          </div>)
      },
      {
        key: 'code',
        dataIndex: 'code',
        title: '部门code',
      },
      {
        key: 'parentName',
        dataIndex: 'parentName',
        title: '上级部门',
      },
      {
        key: 'deptType',
        dataIndex: 'deptType',
        title: '部门类型',
        render: (value, record) => <div>{value=='DEALER' ? '经销商' : '企业'}</div>
      },
      {
        key: 'operate',
        dataIndex: 'operate',
        title: '操作',
        render: (val, record) => (
          <div className="operate">
            {this.props.permissionIds.includes('data.organization.department.add') && record.level != 5 && <a href="javascript:;" onClick={() => { this.handleVisible('sub', record) }}>新增子部门</a>}
            {this.props.permissionIds.includes('data.organization.department.add') && record.level == 5 && <span className="add-disable">新增子部门<p>最多可建立5级部门</p></span>}
            {/* <a href="javascript:;" onClick={() => { this.setState({ deletevisible: `${record.id}_visible` }) }}>删除</a> */}
            {this.props.permissionIds.includes('data.organization.department.status') && <a href="javascript:;" onClick={() => { this.handleModify(record) }}>{this.status[record.status]}</a>}
            {this.props.permissionIds.includes('data.organization.department.allots') && <a href="javascript:;" onClick={() => { this.props.history.push(`/dataorganization/allots?id=${record.id}&name=${record.name}`) }}>分配</a>}
            {/* {this.state.deletevisible == `${record.id}_visible` && <ConfirmPopout
              strongText={'确认删除？'}
              text={'是否确认删除'}
              styles={{ right: 0, }}
              button={['确定', '取消']}
              onOk={() => { this.handleDelete(record) }}
              onCancel={() => { this.setState({ deletevisible: '' }) }}
            />} */}
          </div>)
      },
    ]
  }

  componentDidMount() {
    this.props.departmentSelections({ status: 'ENABLE' });
    this.props.departmentsTree();
    this.props.departments(this.params);
  }

  componentDidUpdate(preProps) {
    // // // 提交成功回调
    if ((/2\d{2}$/.test(this.props.modifyDepartmentsLoaded) && this.props.modifyDepartmentsLoaded !== preProps.modifyDepartmentsLoaded)) {
      this.setState({ visible: false, subvisible: false, namevisible: '', deletevisible: '' });
      this.props.departmentSelections({ status: 'ENABLE' });
      this.props.departmentsTree();
      this.props.departments(this.params);
    }
  }

  renderTreeNodes = (data, key) => data.map((item, index) => {
    if (!item.key) {
      item.key = `${key}-${index}`;
    }
    if (!item.title) {
      item.title = item.name;
    }
    if (item.children && item.children.length) {
      return (
        <TreeNode title={item.title} key={item.key} >
          {this.renderTreeNodes(item.children, item.key)}
        </TreeNode>
      );
    }
    return <TreeNode title={item.title} key={item.key} />;
  })

  search = () => {
    this.setState({
      page: 0,
    });
    this.params.page = 0;
    this.props.departments(this.params);
  }

  onPageChange = (val) => {
    this.setState({ page: val });
    this.params.page = val;
    this.props.departments(this.params);
  }

  onPerPageChange = (val) => {
    this.setState({ size: val });
    this.params.size = val;
    this.params.page = 0;
    this.props.departments(this.params);
  }

  onExpand = (expandedKeys) => {

    console.log(expandedKeys)
    this.setState({
      expandedKeys,
    });
  }

  // 新增部门/子部门
  handleVisible = (type, record) => {
    console.log(record)
    this.setState({ [`${type}visible`]: !this.state[`${type}visible`], record });
    console.log(this.props)
  }

  // 判断部门下是否有子部门
  _canDelete = (data, id) => data.some(val => {
    if (val.id == id && val.children && val.children.length) {
      return true;
    }

    if (val.children) {
      return this._canDelete(val.children, id);
    }
  })

  // // 删除
  // handleDelete = (record) => {
  //   const { departmentsTreeList = [] } = this.props;
  //   if (this._canDelete(departmentsTreeList, record.id)) {
  //     notification.warning('当前部门存在子部门不可删除');
  //   } else {
  //     this.props.modifyDepartments({ id: record.id, method: 'DELETE' });
  //   }
  // }

  // 禁用/启用
  handleModify = (record) => {
    this.props.enableDepartment({ id: record.id, status: record.status == 'ENABLE' ? 'DISABLE' : 'ENABLE' });
  }

  render() {
    const { name, page, size, visible, subvisible, record = {} } = this.state;
    const { loading, departmentsList = {}, departmentsTreeList = [], permissionIds } = this.props;
    const { content, totalElements } = departmentsList;
    return (
      <React.Fragment>
        <div className="DepartmentManage">
          <div className="search-bar">
            {permissionIds.includes('data.organization.department.add') && <Button onClick={() => { this.setState({ visible: true }); }}>新增部门</Button>}
            <div className="search-input-block">
              <SearchInput
                onChange={(val) => { this.params.name = val; this.setState({ name: val }) }}
                onSearch={this.search}
                value={name}
                placeholder="输入部门名称搜索"
              />
            </div>
          </div>

          <div className="block">
            <div className="table-block">
              <div>
                <div className="data-table">
                  <DataTable
                    columns={this.columns}
                    data={content}
                    rowKey="id"
                    loading={loading}
                    rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                    messageRender={({ data, error }) => {
                      if (data == null || data.length === 0) {
                        return <span className="text-weak">暂无部门{permissionIds.includes('data.organization.department.add') && <React.Fragment>，您可以点击左上角<a onClick={() => { this.setState({ visible: true }); }}>新增部门</a></React.Fragment>}</span>
                      }
                    }}
                  />
                </div>
                <Pager
                  total={totalElements}
                  current={page}
                  perPageCountList={[10, 20, 50]}
                  perPageCount={size}
                  onPageChange={this.onPageChange}
                  onPerPageChange={this.onPerPageChange}
                />
              </div>
            </div>
            <div className="tree-block">
              <h3 className="">部门组织架构预览</h3>
              {departmentsTreeList.length && <Tree
                defaultExpandAll
              >
                {this.renderTreeNodes(departmentsTreeList, 0)}
              </Tree>}
            </div>
          </div>
        </div>
        <ModifyDepartment
          visible={visible}
          record={record}
          handleVisible={this.handleVisible}
        />
        <ModifySubDepartment
          visible={subvisible}
          record={record}
          handleVisible={this.handleVisible}
        />

      </React.Fragment>
    )
  }
}

export default DepartmentManage;
