import React from "react";

const SvgIconModify = props => (
  <svg width={11} height={11} {...props}>
    <g fill="#888" fillRule="nonzero">
      <path d="M6.827 1.729L8.555 0 11 2.445 9.27 4.173zM0 8.556V11h2.444l5.963-5.963-2.444-2.444z" />
    </g>
  </svg>
);

export default SvgIconModify;
